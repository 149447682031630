import React from 'react';
import { APFormMessageBox } from 'affinipay-ui-library';

const RecurringChargeReminderMessage = () => (
    <APFormMessageBox header="Want to send your client Scheduled Payments reminders?">
        <span>Go to Settings &gt; <a href="/settings/payment_settings" target="_blank">Payment Settings</a> to activate the scheduled payment reminder emails for all your schedules.</span>
    </APFormMessageBox>
);

export default RecurringChargeReminderMessage;
