import React from 'react';
import userEvent from '@testing-library/user-event';
import { render, screen } from '@testing-library/react';
import AccountHolderFields from './AccountHolderFields';

describe('AccountHolderFields', () => {
  it('renders nothing if component "type" not found', async () => {
    const {container, debug} = render(<AccountHolderFields />);
    debug();
    expect(container.firstChild).toBeNull();
  });

  it('sets individual account fields and does not render business field', () => {
    render(<AccountHolderFields type={'individual'} />);

    const givenName = screen.getByLabelText('First Name');
    const surname = screen.getByLabelText('Last Name');
    const biz = screen.queryByLabelText('Name of Account Holder');

    expect(givenName.value).toEqual('');
    expect(surname.value).toEqual('');

    userEvent.type(givenName, 'Krusty');
    userEvent.type(surname, 'Clown');

    expect(givenName.value).toEqual('Krusty');
    expect(surname.value).toEqual('Clown');
    expect(biz).not.toBeInTheDocument();
  });

  it('sets business account field and does not render individual fields', () => {
    render(<AccountHolderFields type={'business'} />);

    const givenName = screen.queryByLabelText('First Name');
    const surname = screen.queryByLabelText('Last Name');
    const biz = screen.getByLabelText('Name of Business');

    expect(givenName).not.toBeInTheDocument();
    expect(surname).not.toBeInTheDocument();

    userEvent.type(biz, 'Bird Law LLC');
    expect(biz.value).toEqual('Bird Law LLC');
  });
});
