import React, { useState } from 'react';
import { func, string } from 'prop-types';
import { APInputText } from 'affinipay-ui-library';

const IndividualFields = ({ givenName, surname, setGivenName, setSurname }) => {
  const handleSurnameChange = e => setSurname(e.target.value);
  const handleGivenNameChange = e => setGivenName(e.target.value);

  return (
    <>
      <div className="account-detail-field margin-right">
        <APInputText
          dataset={{
            'data-chargeio': 'given_name',
            'data-validation': 'First Name'
          }}
          id="given_name"
          label="First Name"
          onChange={handleGivenNameChange}
          value={givenName}
        />
      </div>
      <div className="account-detail-field">
        <APInputText
          dataset={{
            'data-chargeio': 'surname',
            'data-validation': 'Last Name'
          }}
          id="surname"
          label="Last Name"
          onChange={handleSurnameChange}
          value={surname}
        />
      </div>
    </>  
  );
};

const BusinessFields = ({ accountHolderName, setAccountHolderName }) => {
  const handleAccountHolderNameChange = e => setAccountHolderName(e.target.value);

  return (
    <div className="account-detail-field business">
      <APInputText
        dataset={{
          'data-chargeio': 'name'
        }}
        id='account_holder_name'
        label='Name of Business'
        onChange={handleAccountHolderNameChange}
        value={accountHolderName}
      />
    </div>
  );
};

const AccountHolderFields = props => {
  const [givenName, setGivenName] = useState(props.givenName);
  const [surname, setSurname] = useState(props.surname);
  const [accountHolderName, setAccountHolderName] = useState(props.accountHolderName);
  const fieldTypes = {
    individual: {
      component: IndividualFields,
      props: {givenName, surname, setGivenName, setSurname}
    },
    business: {
      component: BusinessFields,
      props: {accountHolderName, setAccountHolderName}
    }
  };
  const fieldType = props.type?.split('_')[0];
  const field = fieldTypes[fieldType];
  const SpecificField = field?.component;

  return SpecificField ? (
    <div id="account-fields" className="account-fields">
      <SpecificField {...field.props} />
    </div>
  ) : (<></>);
};

IndividualFields.propTypes = {
  givenName: string,
  surname: string,
  setGivenName: func.isRequired,
  setSurname: func.isRequired
};
AccountHolderFields.propTypes = {
  type: string,
  givenName: string,
  surname: string,
  accountHolderName: string
};
BusinessFields.propTypes = {
  accountHolderName: string,
  setAccountHolderName: func.isRequired
};
export default AccountHolderFields;
