import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import { APInputText, APTooltip, APRadioButton } from 'affinipay-ui-library';
import AccountHolderFields from './AccountHolderFields';
import AccountNumberIcon from '../../components/icons/AccountNumberIcon';
import RoutingNumberIcon from '../../components/icons/RoutingNumberIcon';
import './style.scss';

const radioEnum = {
  business: {
    CHECKING: 'account_business_checking',
    SAVINGS: 'account_business_savings'
  },
  individual: {
    CHECKING: 'account_individual_checking',
    SAVINGS: 'account_individual_savings'
  }
};

const RecurringChargeBankAccount = ({
  accountNumber,
  routingNumber,
  bankAccountType,
  accountHolderType
}) => {
  const initialAccountDetails = {
    accountNumber: accountNumber,
    routingNumber: routingNumber
  };

  const initialButtonsData = [
    {
      id: 'account_individual_checking',
      value: 'individual_checking',
      text: 'Personal Checking',
      name: 'account',
      checked: false
    },
    {
      id: 'account_individual_savings',
      value: 'individual_savings',
      text: 'Personal Savings',
      name: 'account',
      checked: false
    },
    {
      id: 'account_business_checking',
      value: 'business_checking',
      text: 'Business Checking',
      name: 'account',
      checked: false
    }, 
    {
      id: 'account_business_savings',
      value: 'business_savings',
      text: 'Business Savings',
      name: 'account',
      checked: false
    }
  ];

  const [accountTypeSelected, setAccountTypeSelected] = useState(accountHolderType || 'individual');
  const [accountDetails, setAccountDetails] = useState(initialAccountDetails);
  const [buttonsData, setButtonsData] = useState(initialButtonsData);

  useEffect(() => {
    setCheckedRadioOnEdit();
  }, []);
  
  const handleAccountDetailsChange = ({ target: { name, value }}) => {
    setAccountDetails({ ...accountDetails, [name]: value });
  };

  const updateButtons = buttonId => {
    const updatedButtonsData = buttonsData.map(data => ({
      ...data,
      checked: buttonId === data.id ? true : false
    }));
    setButtonsData(updatedButtonsData);
  };

  const setCheckedRadioOnEdit = () => {
    let buttonIdToCheck = (!bankAccountType || !accountHolderType) ? 'account_individual_checking' : radioEnum[accountHolderType][bankAccountType];
    
    updateButtons(buttonIdToCheck);
  };

  
  const handleAccountSelection = ({ target: { id, value } }) => {
    updateButtons(id);
    setAccountTypeSelected(value);
  };

  return (
    <section>
      <label className="control-label--uppercase account-information-label">
        Enter Bank Account Information
      </label>
      <div className="account-type-buttons-wrapper">
        <label className="account-type-label" htmlFor="account">Account Type</label>
        {buttonsData.map((el, k) => (
          <div key={el.id} className={`account-type-button ${el.value.split('_')[0]}-${k}`}>
            <APRadioButton
              name="account"
              id={el.id}
              value={el.value}
              checked={!!el.checked}
              text={el.text}
              labelClassNames="radio-inline control-label"
              onChange={handleAccountSelection}
            />
          </div>
        ))}
      </div>
      <div className="account-fields-wrapper">
        <AccountHolderFields type={accountTypeSelected} />
        <div className="account-fields">
          <div className="account-detail-field margin-right">
            <APInputText
              name="routingNumber"
              className="aba_routing_field"
              dataset={{
                'data-chargeio': 'routing_number'
              }}
              id="routing_number"
              label="Routing Number"
              onChange={handleAccountDetailsChange}
              value={accountDetails.routingNumber}
            />
            <APTooltip
              key='routing-number-tooltip'
              id='routing-number-tooltip'
              tooltipContent={<RoutingNumberIcon />}
            />
          </div>
          <div className="account-detail-field">
            <APInputText
              name="accountNumber"
              id="account_number"
              dataset={{
                'data-chargeio': 'account_number'
              }}
              label="Account Number"
              onChange={handleAccountDetailsChange}
              value={accountDetails.accountNumber}
            />
            <APTooltip 
              key='account-number-tooltip' 
              id='account-number-tooltip' 
              tooltipContent={<AccountNumberIcon />} 
            />
          </div>
        </div>
      </div>
      <APInputText
        type="hidden"
        name="method[routing_number]"
        value={accountDetails.routingNumber}
      />
      <APInputText
        type="hidden"
        name="method[account_number]"
        value={accountDetails.accountNumber}
      />
    </section>
  );
};

RecurringChargeBankAccount.propTypes = {
  accountNumber: string,
  routingNumber: string,
  bankAccountType: string,
  accountHolderType: string
};

export default RecurringChargeBankAccount;
