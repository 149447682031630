import React, { useState, useEffect } from 'react';
import BeneficialOwnerForm from './BeneficialOwnerForm';
import { func, number } from 'prop-types';
import { APSpinner, APFormMessageBox } from 'affinipay-ui-library';
import { transformOwnerResponseToFormState, transformBeneficialOwnerFormStateForUpdate } from './helpers/utils';
import notify from 'lib/notify';
import client from 'lib/ajax';

const EditBeneficialOwner = ({
  merchantId,
  beneficialOwnerId,
  setEditedBeneficialOwnerId,
  setCurrentLegalEntity
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [initialFormState, setInitialFormState] = useState({});
  const [formState, setFormState] = useState({});

  useEffect(() => {
    const getBeneficialOwner = async () => {
      try {
        const resp = await client.get(`/admin/merchants/${merchantId}/beneficial_owners/${beneficialOwnerId}`);
        const json = await resp.json();
        if (resp.ok) {
          const newFormState = transformOwnerResponseToFormState(json);
          if (!Object.entries(newFormState).length) {
            setHasError(true);
          }
          else {
            setInitialFormState(newFormState);
            setFormState(newFormState);
          }
        }
        else {
          if (json.error) notify.error(json?.error, [], false);
          setHasError(true);
        }
      }
      catch (e) {
        setHasError(true);
      }
      finally {
        setIsLoading(false);
      }
    };
    getBeneficialOwner();
  }, [merchantId, beneficialOwnerId]);

  const saveBeneficialOwner = async (e) => {
    e.preventDefault();
    setIsSaving(true);
    const data = transformBeneficialOwnerFormStateForUpdate(initialFormState, formState);
    try {
      const resp = await client.patch(`/admin/merchants/${merchantId}/beneficial_owners/${beneficialOwnerId}`, data);
      const json = await resp.json();
      if (resp.ok) {
        setCurrentLegalEntity(previousLegalEntity => ({
          ...previousLegalEntity,
          attributes: {
            ...previousLegalEntity.attributes,
            beneficial_owners: (previousLegalEntity?.attributes?.beneficial_owners || []).map(be => {
              return be?.aggregate_id === json?.aggregate_id ? {...be, ...json} : be;
            })
          }
        }));
        notify.success('Beneficial Owner successfully updated');
        backToLegalEntities();
      }
      else {
        notify.error(json?.error || 'Unexpected error occurred when updating beneficial owner', [], false);
        setIsSaving(false);
      }
    }
    catch (e) {
      notify.error('Unexpected error occurred when updating beneficial owner', [], false);
      setIsSaving(false);
    }
  };

  const backToLegalEntities = () => {
    setEditedBeneficialOwnerId(null);
  };

  return (
    <>
      {hasError ?
        <APFormMessageBox
          header='There was an error retrieving beneficial owner information. Please refresh and try again.'
          type='error'
        />
        :
        <>
          {isLoading ?
            <APSpinner />
            :
            <BeneficialOwnerForm
              disabled={isSaving}
              formState={formState}
              setFormState={setFormState}
              onSubmit={saveBeneficialOwner}
              backToLegalEntities={backToLegalEntities}
              isEditView={true}
            />
          }
        </>
      }
    </>
  );
};

export default EditBeneficialOwner;

EditBeneficialOwner.propTypes = {
  merchantId: number,
  beneficialOwnerId: number,
  setEditedBeneficialOwnerId: func,
  setCurrentLegalEntity: func
};
