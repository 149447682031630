import React, { useState, useEffect } from 'react';
import { func, number } from 'prop-types';
import { APInputText, APInputMask, APSelect, APButton, APSpinner, APFormMessageBox } from 'affinipay-ui-library';
import { usStates, countries } from 'lib/constants';
import { businessStructures } from './helpers/constants';
import notify from 'lib/notify';
import client from 'lib/ajax';
import { transformLegalEntityFormStateForUpdate } from './helpers/utils';

const EditLegalEntity = ({
  merchantId,
  legalEntityId,
  setEditedLegalEntityId,
  setCurrentLegalEntity
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [formState, setFormState] = useState({});
  const [initialFormState, setInitialFormState] = useState({});

  useEffect(() => {
    const getLegalEntity = async () => {
      try {
        const resp = await client.get(`/admin/merchants/${merchantId}/legal_entities/${legalEntityId}`);
        const json = await resp.json();
        if (resp.ok) {
          const data = json?.data?.attributes || {};
          if (Object.entries(data).length) {
            setFormState(data);
            setInitialFormState(data);
          }
          else {
            setHasError(true);
          }
        }
        else {
          if (json.error) notify.error(json?.error, [], false);
          setHasError(true);
        }
      }
      catch (e) {
        setHasError(true);
      }
      finally {
        setIsLoading(false);
      }
    };
    getLegalEntity();
  }, [merchantId, legalEntityId]);

  const saveLegalEntity = async (e) => {
    e.preventDefault();
    setIsSaving(true);
    const data = transformLegalEntityFormStateForUpdate(initialFormState, formState);
    try {
      const resp = await client.patch(`/admin/merchants/${merchantId}/legal_entities/${legalEntityId}`, data);
      const json = await resp.json();
      if (resp.ok) {
        setCurrentLegalEntity(previousLegalEntity => ({
          ...previousLegalEntity,
          attributes: {
            ...previousLegalEntity.attributes,
            ...(json?.data?.attributes || {})
          }
        }));
        notify.success('Legal Entity successfully updated');
        backToLegalEntities();
      }
      else {
        notify.error(json?.error || 'Unexpected error occurred when updating legal entity', [], false);
        setIsSaving(false);
      }
    }
    catch (e) {
      notify.error('Unexpected error occurred when updating legal entity', [], false);
      setIsSaving(false);
    }
  };

  const backToLegalEntities = () => {
    setEditedLegalEntityId(null);
  };

  const onFormChange = (e) => {
    const {name, value} = e.target;
    setFormState(prevFormState => ({...prevFormState, [name]: value}));
  };

  const onFormAddressChange = (e, addressType) => {
    const {name, value} = e.target;
    setFormState(prevFormState => ({
      ...prevFormState,
      [addressType]: {
        ...prevFormState[addressType],
        [name]: value
      }
    }));
  };

  const onFormCountryChange = (e, addressType) => {
    const {name, value} = e.target;
    if (value !== 'us') {
      notify.error('Only US addresses are supported at this time.');
    }
    else {
      setFormState(prevFormState => ({
        ...prevFormState,
        [addressType]: {
          ...prevFormState[addressType],
          [name]: value
        }
      }));
    }
  };

  const {
    phone_number, doing_business_as, legal_business_name, years_in_business, email,
    url, merchant_category_code, tax_id, business_structure,
    address,
    business_address
  } = formState || {};

  const filteredUsStatesOptions = usStates.map(state => ({...state, value: state.value.toLowerCase()}));

  return (
    <>
      {hasError ?
        <APFormMessageBox
          header='There was an error retrieving legal entity information. Please refresh and try again.'
          type='error'
        />
        :
        <>
          {isLoading ?
            <APSpinner />
            :
            <form onSubmit={saveLegalEntity} data-testid='edit-legal-entity-form'>
              <h3 className='legal-entity-title edit'>
                <span>Edit Legal Entity</span>
                <APButton onClick={backToLegalEntities} disabled={isSaving} type='button'>
                  Back to Legal
                </APButton>
              </h3>
              <div className='row bottom-spacer-20'>
                <div className='col-sm-8'>
                  <APInputText
                    name='legal_business_name'
                    value={legal_business_name}
                    label='Legal Business Name'
                    minLength={3}
                    maxLength={40}
                    onChange={onFormChange}
                    parentClass='admin'
                    required={true}
                    disabled={isSaving}
                    helpText="The name registered to the business' tax ID. Limited to 40 characters."
                  />
                </div>
                <div className='col-sm-4'>
                  <APInputText
                    name='tax_id'
                    value={tax_id}
                    label='Tax ID'
                    minLength={9}
                    maxLength={9}
                    onChange={onFormChange}
                    parentClass='admin'
                    required={true}
                    disabled={isSaving}
                  />
                </div>
              </div>
              <div className='row bottom-spacer-20'>
                <div className='col-sm-12'>
                  <APInputText
                    name='doing_business_as'
                    value={doing_business_as || ''}
                    label='Doing Business As'
                    maxLength={24}
                    onChange={onFormChange}
                    parentClass='admin'
                    disabled={isSaving}
                    helpText="The name displayed on payment descriptors on payers' cardholder and bank statements. Limited to 24 characters."
                  />
                </div>
              </div>
              <div className='row bottom-spacer-20'>
                <div className='col-sm-4'>
                  <APSelect
                    name='business_structure'
                    value={business_structure}
                    label='Business Structure'
                    onChange={onFormChange}
                    options={businessStructures}
                    required={true}
                    disabled={isSaving}
                    needsNativeElement={true}
                    parentClass="admin"
                  />
                </div>
                <div className='col-sm-4'>
                  <APInputText
                    name='years_in_business'
                    value={years_in_business}
                    label='Years In Business'
                    min={0}
                    type='number'
                    onChange={onFormChange}
                    parentClass='admin'
                    required={true}
                    disabled={isSaving}
                  />
                </div>
                <div className='col-sm-4'>
                  <APInputText
                    name='merchant_category_code'
                    value={merchant_category_code}
                    label='Merchant Category Code'
                    minLength={4}
                    maxLength={4}
                    onChange={onFormChange}
                    parentClass='admin'
                    required={true}
                    disabled={isSaving}
                  />
                </div>
              </div>
              <div className='row bottom-spacer-20'>
                <div className='col-sm-8'>
                  <APInputText
                    name='address1'
                    value={address?.address1}
                    label='Registered Address'
                    maxLength={255}
                    onChange={ (e) => onFormAddressChange(e, 'address')}
                    parentClass='admin'
                    required={true}
                    disabled={isSaving}
                  />
                </div>
                <div className='col-sm-4'>
                  <APInputText
                    name='address2'
                    value={address?.address2 || ''}
                    label='Additional Address'
                    maxLength={255}
                    onChange={ (e) => onFormAddressChange(e, 'address')}
                    parentClass='admin'
                    disabled={isSaving}
                  />
                </div>
              </div>
              <div className='row bottom-spacer-20'>
                <div className='col-sm-4'>
                  <APInputText
                    name='city'
                    value={address?.city}
                    label='City'
                    minLength={1}
                    maxLength={255}
                    onChange={ (e) => onFormAddressChange(e, 'address')}
                    parentClass='admin'
                    required={true}
                    disabled={isSaving}
                  />
                </div>
                <div className='col-sm-4'>
                  <APSelect
                    name='state'
                    value={address?.state}
                    label='State'
                    onChange={ (e) => onFormAddressChange(e, 'address')}
                    options={filteredUsStatesOptions}
                    required={true}
                    disabled={isSaving}
                    needsNativeElement={true}
                    parentClass="admin"
                  />
                </div>
                <div className='col-sm-4'>
                  <APInputMask
                    name='zip_code'
                    value={address?.zip_code}
                    label='Postal Code'
                    mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                    onChange={ (e) => onFormAddressChange(e, 'address')}
                    parentClass="admin"
                    required={true}
                    disabled={isSaving}
                  />
                </div>
              </div>
              <div className='row bottom-spacer-20'>
                <div className='col-sm-6'>
                  <APSelect
                    name='type'
                    value={address?.type}
                    label='Country'
                    onChange={ (e) => onFormCountryChange(e, 'address')}
                    options={countries.map(country => ({...country, value: country.value.toLowerCase()}))}
                    required={true}
                    disabled={isSaving}
                    needsNativeElement={true}
                    parentClass="admin"
                  />
                </div>
              </div>
              <h4>Information on Payment Pages</h4>
              <div className='row bottom-spacer-20'>
                <div className='col-sm-8'>
                  <APInputText
                    name='address1'
                    value={business_address?.address1}
                    label='Business Address'
                    maxLength={255}
                    onChange={ (e) => onFormAddressChange(e, 'business_address')}
                    parentClass='admin'
                    required={true}
                    disabled={isSaving}
                  />
                </div>
                <div className='col-sm-4'>
                  <APInputText
                    name='address2'
                    value={business_address?.address2 || ''}
                    label='Additional Address'
                    maxLength={255}
                    onChange={ (e) => onFormAddressChange(e, 'business_address')}
                    parentClass='admin'
                    disabled={isSaving}
                  />
                </div>
              </div>
              <div className='row bottom-spacer-20'>
                <div className='col-sm-4'>
                  <APInputText
                    name='city'
                    value={business_address?.city}
                    label='City'
                    minLength={1}
                    maxLength={255}
                    onChange={ (e) => onFormAddressChange(e, 'business_address')}
                    parentClass='admin'
                    required={true}
                    disabled={isSaving}
                  />
                </div>
                <div className='col-sm-4'>
                  <APSelect
                    name='state'
                    value={business_address?.state}
                    label='State'
                    onChange={ (e) => onFormAddressChange(e, 'business_address')}
                    options={filteredUsStatesOptions}
                    required={true}
                    disabled={isSaving}
                    needsNativeElement={true}
                    parentClass="admin"
                  />
                </div>
                <div className='col-sm-4'>
                  <APInputMask
                    name='zip_code'
                    value={business_address?.zip_code}
                    label='Postal Code'
                    mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                    onChange={ (e) => onFormAddressChange(e, 'business_address')}
                    parentClass="admin"
                    required={true}
                    disabled={isSaving}
                  />
                </div>
              </div>
              <div className='row bottom-spacer-20'>
                <div className='col-sm-6'>
                  <APSelect
                    name='type'
                    value={business_address?.type}
                    label='Country'
                    onChange={ (e) => onFormCountryChange(e, 'business_address')}
                    options={countries.map(country => ({...country, value: country.value.toLowerCase()}))}
                    required={true}
                    disabled={isSaving}
                    needsNativeElement={true}
                    parentClass="admin"
                  />
                </div>
              </div>
              <div className='row bottom-spacer-20'>
                <div className='col-sm-8'>
                  <APInputText
                    name='email'
                    value={email}
                    label='Email'
                    onChange={onFormChange}
                    parentClass='admin'
                    disabled={isSaving}
                  />
                </div>
                <div className='col-sm-4'>
                  <APInputMask
                    name='phone_number'
                    value={phone_number}
                    label='Phone'
                    mask={[/[0-9]/, '(', /[0-9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                    onChange={onFormChange}
                    parentClass="admin"
                    required={true}
                    disabled={isSaving}
                  />
                </div>
              </div>
              <div className='row bottom-spacer-20'>
                <div className='col-sm-12'>
                  <APInputText
                    name='url'
                    value={url || ''}
                    label='Website'
                    maxLength={255}
                    onChange={onFormChange}
                    parentClass='admin'
                    disabled={isSaving}
                    helpText='Must start with http:// or https://'
                  />
                </div>
              </div>
              <div className='row bottom-spacer-20'>
                <div className='col-sm-12'>
                  <APButton type='submit' disabled={isSaving}>
                    Save Changes
                  </APButton>
                </div>
              </div>
            </form>
          }
        </>
      }
    </>
  );
};

export default EditLegalEntity;

EditLegalEntity.propTypes = {
  merchantId: number,
  legalEntityId: number,
  setEditedLegalEntityId: func,
  setCurrentLegalEntity: func
};
