import React from 'react';
import userEvent from '@testing-library/user-event';
import { render, screen } from '@testing-library/react';
import RecurringChargeBankAccount from './RecurringChargeBankAccount';

describe('RecurringChargeBankAccount Component', () => {
  it('renders without props', () => {
    const renderedText = [
      'Enter Bank Account Information',
      'Account Type',
      'Personal Checking',
      'Personal Savings',
      'Business Checking',
      'Business Savings',
      'First Name',
      'Last Name',
      'Routing Number',
      'Account Number'
    ];
    render(<RecurringChargeBankAccount />);    
    renderedText.forEach(text => expect(screen.getByText(text)).toBeVisible());
  });

  it('update state for account and routing numbers', () => {
    render(<RecurringChargeBankAccount />);
    const accountNum = '987654321';
    const routingNum = '100000013';
    const routingInput = screen.getByLabelText('Routing Number');
    const accountInput = screen.getByLabelText('Account Number');
    userEvent.type(routingInput, routingNum);
    userEvent.type(accountInput, accountNum);

    expect(routingInput.value).toBe(routingNum);
    expect(accountInput.value).toBe(accountNum);
  });

  it('shows correct fields based on account type selected', () => {
    render(<RecurringChargeBankAccount />);
    const firstNameInput = screen.getByLabelText('First Name');
    const lastNameInput = screen.getByLabelText('Last Name');
    const accountInput = screen.getByLabelText('Account Number');
    const routingInput = screen.getByLabelText('Routing Number');
    const businessgInput = screen.queryByLabelText('Name of Business');

    [firstNameInput, lastNameInput, accountInput, routingInput].forEach(field => {
      expect(field).toBeVisible();
    });

    expect(businessgInput).not.toBeInTheDocument();

    userEvent.click(screen.getByLabelText('Business Checking'));
    expect(screen.getByLabelText('Name of Business')).toBeVisible();
    expect(firstNameInput).not.toBeInTheDocument();
    expect(lastNameInput).not.toBeInTheDocument();

    userEvent.click(screen.getByLabelText('Personal Checking'));
    expect(screen.queryByLabelText('Name of Business')).not.toBeInTheDocument();
    expect(screen.getByLabelText('First Name')).toBeVisible();
    expect(screen.getByLabelText('Last Name')).toBeVisible();

    userEvent.click(screen.getByLabelText('Business Savings'));
    expect(screen.getByLabelText('Name of Business')).toBeVisible();
    expect(firstNameInput).not.toBeInTheDocument();
    expect(lastNameInput).not.toBeInTheDocument();

    userEvent.click(screen.getByLabelText('Personal Savings'));
    expect(screen.queryByLabelText('Name of Business')).not.toBeInTheDocument();
    expect(screen.getByLabelText('First Name')).toBeVisible();
    expect(screen.getByLabelText('Last Name')).toBeVisible();
  });

  it('business checking checkbox is selected when passed the correct props', () => {
    render(<RecurringChargeBankAccount {...getMockProps('checking-business')} />);
    expect(screen.getByLabelText('Business Checking').checked).toBe(true);
    expect(screen.getByLabelText('Personal Checking').checked).toBe(false);
    expect(screen.getByLabelText('Business Savings').checked).toBe(false);
    expect(screen.getByLabelText('Personal Savings').checked).toBe(false);
  });

  it('personal checking checkbox is selected when passed the correct props', () => {
    render(<RecurringChargeBankAccount {...getMockProps('checking-individual')} />);
    expect(screen.getByLabelText('Business Checking').checked).toBe(false);
    expect(screen.getByLabelText('Personal Checking').checked).toBe(true);
    expect(screen.getByLabelText('Business Savings').checked).toBe(false);
    expect(screen.getByLabelText('Personal Savings').checked).toBe(false);
  });

  it('business savings checkbox is selected when passed the correct props', () => {
    render(<RecurringChargeBankAccount {...getMockProps('savings-business')} />);
    expect(screen.getByLabelText('Business Checking').checked).toBe(false);
    expect(screen.getByLabelText('Personal Checking').checked).toBe(false);
    expect(screen.getByLabelText('Business Savings').checked).toBe(true);
    expect(screen.getByLabelText('Personal Savings').checked).toBe(false);
  });

  it('business checking checkbox is selected when passed the correct props', () => {
    render(<RecurringChargeBankAccount {...getMockProps('savings-individual')} />);
    expect(screen.getByLabelText('Business Checking').checked).toBe(false);
    expect(screen.getByLabelText('Personal Checking').checked).toBe(false);
    expect(screen.getByLabelText('Business Savings').checked).toBe(false);
    expect(screen.getByLabelText('Personal Savings').checked).toBe(true);
  });
});

const getMockProps = (type) => {
  switch (type) {
  case 'checking-business':
    return {
      bankAccountType: 'CHECKING',
      accountHolderType: 'business'
    };
  case 'savings-business':
    return {
      bankAccountType: 'SAVINGS',
      accountHolderType: 'business'
    };
  case 'checking-individual':
    return {
      bankAccountType: 'CHECKING',
      accountHolderType: 'individual'
    };
  case 'savings-individual':
    return {
      bankAccountType: 'SAVINGS',
      accountHolderType: 'individual'
    };
  default:
    return;
  }
};
